/* import __COLOCATED_TEMPLATE__ from './general.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';

type Args = {
  model: $TSFixMe;
  hideLauncherSettings: boolean;
};

export default class General extends Component<Args> {
  @service declare messengerSettingsService: MessengerSettingsService;
  @service declare appService: $TSFixMe;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::General': typeof General;
    'messenger-settings/general': typeof General;
  }
}
