/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import { computed } from '@ember/object';
import Mixin from '@ember/object/mixin';
let ProfileAttributeMixin = Mixin.create({
  attributeComponentPath: computed('attribute.type', function () {
    let attributeType = this.get('attribute.type');
    let stringyTypes = [
      'downcased_string',
      'ios_device',
      'ios_version',
      'ios_app_version',
      'android_app_version',
      'android_device',
      'android_version',
      'account_id',
      'user_event',
      'vip_query',
    ];
    if (stringyTypes.includes(attributeType)) {
      attributeType = 'string';
    }
    return `attribute-${attributeType}-component`;
  }),
});
export default ProfileAttributeMixin;
