/* import __COLOCATED_TEMPLATE__ from './create-tag-from-csv.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { captureException } from 'embercom/lib/sentry';
import PredicateGroup from 'predicates/models/predicate-group';
import { uploadStates } from './create-tag-from-csv/upload';
import { tagStates } from './create-tag-from-csv/tag';

export default class CreateTagFromCsv extends Component {
  @service appService;
  @service intercomEventService;
  @service intl;
  @service notificationsService;
  @service router;
  @service store;

  @tracked uploadState = uploadStates.notStarted;
  @tracked tagState = tagStates.editing;
  @tracked csvImportRecord;
  @tracked tag;

  @task({ drop: true })
  *userSearchTask() {
    this.csvImportRecord.manualTagId = this.tag.id;
    yield this.csvImportRecord.updateTag();
    yield this.csvImportRecord.enqueueUserSearch();
  }

  @action
  async userSearch() {
    try {
      await this.userSearchTask.perform();
      await this.app.loadAndUpdateTags();
      this.insertPredicate();
      this.notificationsService.notifyConfirmation(
        this.intl.t('modal.create-tag-from-csv.confirmation', {
          tagName: this.tag.name,
        }),
      );
      this.intercomEventService.trackEvent('csv_tag_event', {
        action: 'start',
        place: 'csv_tag_modal',
        object: 'user_search',
        csv_import_id: this.csvImportRecord.id,
      });
      this.args.closeModal();
    } catch (error) {
      captureException(error);
      this.uploadState = uploadStates.failed;
      let errorMessage;
      if (error.jqXHR?.status === 404) {
        errorMessage = this.intl.t('modal.create-tag-from-csv.errors.record-not-found');
      } else {
        errorMessage = error.message || error.jqXHR?.responseJSON?.error;
      }
      this.notificationsService.notifyError(errorMessage);
    }
  }

  @action
  onUploadFinish(csvImportRecord) {
    this.csvImportRecord = csvImportRecord;
  }

  @action
  onTagSaved(tag) {
    this.tag = tag;
  }

  @action
  onUploadStateChange(newState) {
    this.uploadState = newState;
    if (this.uploadState === uploadStates.notStarted) {
      this.csvImportRecord = null;
    }
  }

  @action
  onTagStateChange(newState) {
    this.tagState = newState;
  }

  get tagErrorClass() {
    if (this.tagState === tagStates.failure) {
      return 'text-error';
    }
    return '';
  }

  get isSaveDisabled() {
    if (this.uploadState === uploadStates.finished && this.tagState === tagStates.saved) {
      return false;
    }

    return true;
  }

  get app() {
    return this.appService.app;
  }

  get predicateGroup() {
    return this.args.predicateGroup;
  }

  get columnAssignments() {
    return this.csvImportRecord?.columnAssignments?.map((col) => ({
      ...col,
      external_user_field_name:
        this.store.peekRecord('attribute', col.external_user_field)?.name ||
        col.external_user_field,
    }));
  }

  get audienceRoleCopy() {
    let text = '';
    let selectedUserTypes = ['user'];
    if (this.args.selectedUserTypes?.length > 0) {
      selectedUserTypes = this.args.selectedUserTypes;
    }
    if (selectedUserTypes.includes('user') && selectedUserTypes.includes('lead')) {
      text = this.intl.t(`modal.create-tag-from-csv.record-type.lead-user`);
    } else if (selectedUserTypes.includes('user')) {
      text = this.intl.t(`modal.create-tag-from-csv.record-type.user`);
    } else if (selectedUserTypes.includes('lead')) {
      text = this.intl.t(`modal.create-tag-from-csv.record-type.lead`);
    }
    return text;
  }

  get modalDescriptionText() {
    let companyDataUrl = this.router.urlFor('apps.app.settings.data.companies');
    let peopleDataUrl = this.router.urlFor('apps.app.settings.data.people');
    return this.intl.t('modal.create-tag-from-csv.description', {
      peopleDataUrl,
      companyDataUrl,
      htmlSafe: true,
    });
  }

  insertPredicate() {
    this.predicateGroup.add(this.tag.predicate);
    let serializedPredicates = this.predicateGroup.predicates.serialize();
    let newPredicateArray = PredicateGroup.createPredicatesArray(
      this.store,
      serializedPredicates,
      null,
    );
    this.args.updatePredicates(newPredicateArray, { action: 'added' });
  }
}
