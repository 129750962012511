/* import __COLOCATED_TEMPLATE__ from './predicate-summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { assert } from '@ember/debug';
import { inject as service } from '@ember/service';

interface Signature {
  Args: {
    predicate: $TSFixMe;
    ruleMode: string;
    mode?: 'goal' | undefined;
    showRule?: boolean;
    hideIcons?: boolean;
    isInbox2?: boolean;
  };
  Element: HTMLSpanElement;
}

export default class PredicateSummary extends Component<Signature> {
  @service declare appService: { app: { canUseStandalone: boolean } };

  get predicate() {
    assert('predicate-summary: predicate must be defined', !!this.args.predicate);
    return this.args.predicate;
  }

  get isGoalMode() {
    return this.args.mode === 'goal';
  }

  get predicateDescriptionPrefix() {
    return this.isGoalMode
      ? this.predicate.goalDescriptionPrefix
      : this.predicate.descriptionPrefix;
  }

  get predicateDescription() {
    return this.isGoalMode ? this.predicate.goalDescription.shortText : this.predicate.description;
  }

  get hidePredicate() {
    return this.appService.app?.canUseStandalone && this.predicate.type === 'role';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'matching-system/audience-selector/summary/predicate-summary': typeof PredicateSummary;
    'MatchingSystem::AudienceSelector::Summary::PredicateSummary': typeof PredicateSummary;
  }
}
