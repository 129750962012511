/* import __COLOCATED_TEMPLATE__ from './user-role-selector.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import UserRoleSelectorItem from 'embercom/components/matching-system/audience-selector/user-role-selector-item';

export default Component.extend({
  // All the options supported by the user role predicate (lib/predicates/addon/models/role)
  // Override this list with the options that are relevant to your feature when using this component
  optionsToDisplay: [
    'user',
    'lead',
    'visitor',
    'non_user', // This targets visitors & leads
  ],

  intl: service(),

  dropdownItems: computed('optionsToDisplay', function () {
    return this.allDropdownItems.filter((item) => this.optionsToDisplay.includes(item.value));
  }),

  allDropdownItems: computed('intl.locale', function () {
    return [
      {
        text: this.intl.t('matching-system.audience-selector.user-role-selector.visitors'),
        value: 'visitor',
        description: this.intl.t(
          'matching-system.audience-selector.user-role-selector.visitors-description',
        ),
        component: UserRoleSelectorItem,
      },
      {
        text: this.intl.t('matching-system.audience-selector.user-role-selector.leads'),
        value: 'lead',
        description: this.intl.t(
          'matching-system.audience-selector.user-role-selector.leads-description',
        ),
        component: UserRoleSelectorItem,
      },
      {
        text: this.intl.t(
          'matching-system.audience-selector.user-role-selector.visitors-and-leads',
        ),
        value: 'non_user',
        component: UserRoleSelectorItem,
      },
      {
        text: this.intl.t('matching-system.audience-selector.user-role-selector.users'),
        value: 'user',
        description: this.intl.t(
          'matching-system.audience-selector.user-role-selector.users-description',
        ),
        component: UserRoleSelectorItem,
      },
    ];
  }),
});
