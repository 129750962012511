/* import __COLOCATED_TEMPLATE__ from './bulk-archive-users.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { inject as service } from '@ember/service';
import BulkArchiveBaseComponent from 'embercom/components/modal/user-or-company-deletion/bulk-archive-base-component';
import { or, alias } from '@ember/object/computed';
export default BulkArchiveBaseComponent.extend({
  intercomEventService: service(),
  intl: service(),
  entityString: alias('personTypeName'),
  pluralizedEntity: 'users',
  countToUse: or('selected.length', 'totalCount'),

  get archiveTarget() {
    if (this.personTypeName === 'user') {
      return this.intl.t('components.modal.user-deletion.bulk-archive-users.title-user', {
        count: this.countToUse,
      });
    } else {
      return this.intl.t('components.modal.user-deletion.bulk-archive-users.title-lead', {
        count: this.countToUse,
      });
    }
  },
  get archiveButtonLabel() {
    return this.intl.t('components.modal.user-deletion.bulk-archive-users.archive-pluralName', {
      pluralName: this.personTypeName,
    });
  },

  trackAnalytics(count) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'archived',
      object: 'users',
      place: 'platform',
      context: 'from_ui',
      predicates: this.predicates,
      count_of_archived_users: count,
    });
  },
});
