/* import __COLOCATED_TEMPLATE__ from './user-role-selector-item.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  item: {
    text: string;
    description: string;
  };
}

const UserRoleSelectorItem = templateOnlyComponent<Args>();

export default UserRoleSelectorItem;
