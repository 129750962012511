/* import __COLOCATED_TEMPLATE__ from './loading-button.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { tracked } from 'tracked-built-ins';
import { taskFor } from 'ember-concurrency-ts';
import { action } from '@ember/object';
import ENV from 'embercom/config/environment';
import { type TaskGenerator } from 'ember-concurrency';

export interface Args {
  disabled?: boolean;
  onClickTask: () => TaskGenerator<void>;
  label: string;
  displayStrings: string[];
  showOverlay?: boolean;
  isProcessing: boolean;
}

interface Signature {
  Args: Args;
  Blocks: any;
  Element: HTMLElement;
}

export default class LoadingButton extends Component<Signature> {
  @tracked interval: NodeJS.Timeout | null = null;
  @tracked processingLabelIndex = 0;
  @tracked timerRunning = false;

  startTimer() {
    this.timerRunning = true;
    this.processingLabelIndex = 0;
    this.interval = setInterval(() => {
      if (this.processingLabelIndex >= this.args.displayStrings.length - 1) {
        if (this.interval) {
          clearInterval(this.interval);
          this.interval = null;
        }
      } else {
        this.processingLabelIndex++;
      }
    }, ENV.APP.loadingButtonContentDelay);
  }

  get processingLabel() {
    return this.args.displayStrings[this.processingLabelIndex];
  }

  @action
  maybeStopTimer() {
    if (!this.args.isProcessing) {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
      if (this.timerRunning) {
        this.timerRunning = false;
      }
    }
  }

  @action
  startProcessing() {
    taskFor(this.args.onClickTask).perform();
    this.startTimer();
  }

  get showProcessingState() {
    return this.timerRunning && this.args.isProcessing;
  }

  willDestroy() {
    super.willDestroy();
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    this.timerRunning = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'LoadingButton::LoadingButton': typeof LoadingButton;
    'loading-button/loading-button': typeof LoadingButton;
  }
}
