/* import __COLOCATED_TEMPLATE__ from './avatar.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { hasFeature } from 'embercom/helpers/has-feature';

export default class PreviewAvatar extends Component {
  @service appService;

  get avatarURL() {
    return this.args.teammate.avatarData?.url || this.args.teammate.avatar?.image_urls?.square_128;
  }

  get hasAvatar() {
    if (this.args.teammate.isOperator) {
      return this.args.teammate.avatarData?.url;
    }
    return this.args.teammate.has_photo_avatar;
  }

  get initial() {
    let initials = this.args.teammate.avatar?.initials;
    return initials && initials.length > 0 ? initials.charAt(0).toUpperCase() : null;
  }

  get pxSize() {
    if (this.args.large) {
      if (hasFeature('team-messenger-unified-messenger', this.appService)) {
        return 56;
      }

      return 64;
    } else if (this.args.small) {
      return 16;
    } else if (this.args.displayAsCompact) {
      return 28;
    } else {
      return 32;
    }
  }

  get avatarSizes() {
    return `width:${this.pxSize}px;height:${this.pxSize}px`;
  }

  get shape() {
    return this.args.teammate.avatarData?.avatarShape;
  }

  get hasNonDefaultShape() {
    let defaultShape = 'circle';
    return Boolean(this.shape && this.shape !== defaultShape);
  }

  get shapeClass() {
    return `o__shape-${this.shape}`;
  }
}
