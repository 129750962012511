/* import __COLOCATED_TEMPLATE__ from './import-summary-modal.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ImportSummaryModal extends Component {
  @service appService;
  @service modalService;

  get app() {
    return this.appService.app;
  }

  @action
  closeModal() {
    this.modalService.closeModal();
  }
}
